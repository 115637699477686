@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  background: #fff8ec;
}

html, body, #root, .App {
  position: relative;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.progress-bar {
  background-color: #e96400 !important;
}

.page-item.active .page-link {
  background-color: #e96400 !important;
  border-color: #e96400 !important;
}
