.signup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .container {
        width: 500px;
        h2 {
            text-align: center; }
        .signupForm {
            label {
                margin-bottom: 0; }
            .submit {
                margin-top: 30px;
                text-align: center; } }
        .agreement {
            font-size: 12px;
            text-align: center; } } }
