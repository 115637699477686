.passwordRecovery {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .container {
        width: 400px;
        h2 {
            text-align: center; }
        .passwordRecoveryForm {
            label {
                margin-bottom: 0; }
            .submit {
                margin-top: 30px;
                text-align: center; } } }
    .signupLink {
        text-align: center; } }
