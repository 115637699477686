@import '../../variables';

.announcementItem {
    display: block;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 8px 10px;
    margin: 10px 0; }
.danger {
    background: $warning;
    color: white; }
