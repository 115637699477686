@import './variables';

html, body {
    position: relative;
    height: 100%; }
body {
    background-color: $mainBG;
    .content-container {
        width: 100%;
        max-width: 1440px;
        margin: 20px auto;
        padding: 0 20px; }
    #root, .App {
        min-height: 100%;
        h1, h2, h3, h4, h5, h6 {}
        a {
            color: $linkColor; } }

    .btn-success {
        background-color: $green; }
    .btn-success.disabled {
        background-color: $green + #333; }
    .badge-success {
        background-color: $green; } }
