@import 'variables.sass';
@import 'react-nice-dates/src/style.scss';

.nice-dates {
    .nice-dates-popover {
        max-width: 400px;
        .nice-dates-day:before {
            @include gradientPrimary; }
        .nice-dates-day:after {
            border-color: $textColor_Primary; } }
    .input {
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        color: #495057;
        border: 1px solid #ced4da;
        border-radius: 3px;
        padding: .375rem .75rem;
        &.-focused {
            outline: none; } } }
body {
    color: $textColor_Primary; }

.btn {
    border: none;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    &.btn-primary {
        @include gradientPrimary; } }

h1 {}

h2 {}

h3 {}

input, textarea {
    &.invalid {
        border-color: red !important; } }

.icon {
    cursor: pointer;
    transition: all 0.2s ease;
    color: $textColor_Primary;
    font-size: 20px;
    &:hover {
        color: $linkColor_Hover; } }

.progress {
    border-radius: 10px;
    height: 10px;
    overflow: hidden;
    width: 100%; }

.text-right {
    text-align: right; }

.text-center {
    text-align: center; }

.bolder {
    font-weight: 600; }

.badge {
    font-size: $badgesFontSize; }

.fieldMessage {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
    &[data-type="error"] {
        color: red; } }

.error {
    color: red; }

p {
    margin: 10px 0; }

.mobileOverflowX {
    overflow-x: auto; }
