@import "../../../variables";

.fullDescription {}

.lotDetails {
    display: flex; }
.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    span {
        font-size: 18px; } }
.roadMap {
    list-style: none;
    padding: 0;
    width: 100%;
    li {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: solid 1px $mainBG;
        span {
            position: relative;
            display: inline-block; }
        .roadMapTitle {
            font-weight: 600;
            font-size: 18px; }
        .roadMapDescription {
            width: 73%; }
        .roadMapStartDate {
            width: 25%;
            border-left: solid 3px $textColor_Primary;
            padding-left: 20px;
            font-size: 20px;
            display: flex;
            align-items: center; }
        .roadMapStatus {
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50px;
            border: solid 3px $textColor_Primary;
            padding: 0;
            left: -12px;
            background: $white;
            top: 50%;
            transform: translateY(-50%);
            &[data-funded="true"] {
                background: $linkColor; }
            &[data-completed="true"] {
                background: $green; } }
        .roadMapCost {
            font-style: italic;
            font-weight: bold; }
        .roadMapStageHeader {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 10px; }
        .roadMapStageActions {
            margin-top: 10px;
            margin-bottom: 10px; } } }

.documentList {
    padding: 0;
    list-style: none;
    li {
        font-size: 16px;
        display: flex;
        align-items: center;
        border-bottom: solid 1px $mainBG;
        padding: 8px 0;
        svg {
            margin-right: 10px; }
        a {
            font-weight: 600;
            color: $textColor_Primary; } } }

@media screen and (max-width: 500px) {
    .lotDetails {
        display: flex;
        flex-wrap: wrap;
        .roadMapStartDateValue {
            transform: rotate(-90deg);
            margin-left: -45px; }
        .roadMap li {
            .roadMapStartDate {
                width: 8%; }
            .roadMapDescription {
                width: 92%; } } } }

.modalContent {
    .numberInput {
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0; } } }
