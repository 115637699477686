.LotsItem {
    button {
        margin-right: 10px; }
    .details {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        table {
            width: 100%;
            margin-top: 20px; }
        .dateTableColumn, .stageTableColumn {
            width: 20%; } } }

