@import '../../../variables';

.mainmenu {
    .link {
        color: $linkColor;
        font-size: 12px;
        font-weight: 700;
        margin: 0 10px;
        padding: 3px 15px;
        border-radius: 15px;
        a {
            color: $linkColor; }
        &.active {
            @include gradientPrimary;
            a {
                color: $textColor_White !important; } } } }

