@import '../../variables';



.header {
    box-shadow: 3px 3px 3px #ddd;
    background-color: $headerBG;
    z-index: 5;
    .desktop, .mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 20px;
        width: 100%; }
    .logoContainer {
        width: auto; }
    .logo {
        width: 50px;
        margin-top: -5px;
        display: inline-block;
        img {
            max-width: 100%; } }
    .mobile > * {
        width: 33%; }
    .mobileMenu {
        z-index: 10;
        .menuIcon {
            color: $textColor_Primary;
            font-size: 18px; }
        .dropdown {
            max-height: 0px;
            overflow: hidden;
            position: absolute;
            background: #fff;
            top: 55px;
            left: 3%;
            width: 94%;
            border-radius: 10px;
            box-shadow: 2px 2px 5px #ddd;
            &.dropdownActive {
                max-height: 100%; } } } }

@media screen and (min-width: 500px) {
    .header .mobile {
        display: none; } }
@media screen and (max-width: 500px) {
    .header .desktop {
        display: none; }
    .dropdown {
        max-height: 100%;
        nav ul {
            flex-direction: column;
            li {
                margin-top: 5px !important;
                margin-bottom: 5px !important; } }
        .localeSnippet {
            position: absolute;
            right: 10px;
            top: 5px;
            z-index: 1; } } }
