@import "../../variables";

.investmentsWrapper {
    display: flex;
    flex-direction: column;
    .pieContainer {
        width: 100%;
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .investmentsLotSlider {
        display: flex;
        justify-content: space-between;
        h5 {
            color: $linkColor; }
        .ivestmentsLotSliderItem {
            padding: 0 10px;
            table {
                width: 100%;
                td {
                    padding: 0;
                    border: none;
                    &:first-child {
                        padding-right: 15px; } } } } }

    .myInvestmentsItem {
        color: $textColor_Primary;
        h4 {
            color: $linkColor; }
        .myInvestmentsItemDetails {
            display: flex;
            justify-content: space-between;
            & > * {
                width: 45%; }
            table {
                th {
                    color: $textColor_Primary; } } } }
    .scrollArrow {
        margin: 0 10px; }
    .scrollMenuContainer {
        max-width: 100%; }
    .scrollItemWrapper {
        &:focus {
            outline: none; } }
    .scrollItemActive {
        &:focus {
            outline: none; }
        div {
            &:focus {
                outline: none; } } }
    .scrollArrowDisabledClass {
        display: none; } }


