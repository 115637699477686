.AllLotsTopFilterComponent {
    .minSummForm, .summaryValue {
        flex-flow: row nowrap;
        label {
            width: auto;
            margin-right: 10px; }
        input {
            margin-right: 10px;
            width: 150px; } } }

.sortByDate {
    display: flex;
    align-items: center;
    span {
        margin-right: 10px; } }

@media screen and (max-width: 500px) {
    .AllLotsTopFilterComponent {
        flex-flow: row wrap;
        .minSummForm, .summaryValue {
            flex-flow: row wrap;
            .formGroup {
                width: 100%;
                input {
                    width: 100%; } } } } }

