@import '../../../variables';

.profileSnippet {
    display: flex;
    align-items: center;
    .profileSnippetContentWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        .userName {
            font-weight: 700;
            font-size: 12px;
            color: $textColor_Primary; }
        .avatar {
            background: $mainBG;
            border-radius: 30px;
            overflow: hidden;
            margin: 0 15px;
            border: solid 1px #777;
            img {
                width: 30px;
                height: 30px; } }
        .profileMenuButton {} } }

.button {
    margin-left: 10px; }

@media screen and (max-width: 500px) {
    .profileSnippet {
        .userName, .avatar, .profileMenuButton {
            display: none; } } }
