@import "../../../variables";

.roadmapItem {
    margin-bottom: 20px; }

.documentList {
    padding: 0;
    list-style: none;
    li {
        font-size: 16px;
        display: flex;
        align-items: center;
        border-bottom: solid 1px $mainBG;
        padding: 8px 0;
        svg {
            margin-right: 10px; }
        a {
            font-weight: 600;
            color: $textColor_Primary; } } }

.lotEditorInvalid {
    > :global.tox-tinymce {
        border: 1px solid red; } }


input[type=number].numberInput {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; } }

.hidden {
    display: none; }
