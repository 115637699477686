.process {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;
    span {
        width: 50%;
        font-weight: 600;
        margin-bottom: 5px; }
    .remaining {
        text-align: right; } }
