$headerBG: #fff;
$mainBG: #fff8f3;
$containerBG: #fff;
$textColor_Primary: #2E4861;
$textColor_Secondary: #8DA1AC;
$textColor_White: #fff;
$linkColor: #457a00;
$linkColor_Hover: #457a00 - #222;
$white: #fff;
$green: #457a00;
$danger: #e20000;
$warning: #db3e00;

$badgesFontSize: 13px;

@mixin gradientPrimary {
    background: linear-gradient(270deg, #61a904 0%, #61a904 77.08%);
    &:hover {
        background: linear-gradient(270deg, #61a904 + #222 0%, #61a904 + #222 77.08%); } }

@mixin gradientSecondary {
    background: linear-gradient(270deg, #3BFBD8 0%, #1DBEBE 77.08%); }
